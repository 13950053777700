/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "react-virtualized/styles.css";
import "./App.css";
import "./views/KBView/KBstyle.css";
import { CCol, CRow } from "@coreui/react";
import { Authcontext } from "../src/views/components/Context/Context";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import LogoKBIcon from "./assets/katbook_logo.png";
// import KBView from "./views/KBView/KBView";
import { TheContent } from "./containers";
import EmployeeContext from "./Services/EmployeeContext";
import GlobalContext from "./Services/GlobalContext";
import LocalizedStrings from "react-localization";
// import localizations from "../src/localization";
import './views/katbookInstitation/KatbookInstition.css'

import {
  getUserProfile,
  KampusLogin,
  getKampusStudentLogin,
  deleteKampusStudentLogin,
  getAllLog,
} from "./Services/ApiService";
// import Model from "./views/components/Model/Model";
import UserLogModel from "./views/components/Model/UserLogModel";
import address from "address";
import Danger from "./views/components/Toastify/Danger";
import Progress from "./views/components/Progress/Progress";
import Warning from "./views/components/Toastify/Warning";

// let translations = new LocalizedStrings(localizations);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const Login = React.lazy(() => import("./views/Login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const initialState = {
  isAuthenticated: false,
  katbookInitiation: "",
  userType: null,
  user: null,
  email: "",
  _id: "",
  token: null,
  userTypeId: "",
  userTypes: "",
  UserTypecode: "",
  route: "",
  subRoute: "",
  companyId: "6007cfc79052d71fec82fef2",
  companyName: "KATBOOK CONTENT DEVELOPMENT",
  institutionName: "",
  student: false,
  userLogger: false,
  institutionUser: false,
  log: "",
  loginUserType: false,
  url: "",
  teacherSelectedSection: "",
  teacherSelectedclass: "",
  leftBar: true,
  stateColor: '#00356D',
  isThirdParty: false
};
const reducer = (state, action) => {
  switch (action.type) {
    case "Profile":
      localStorage.setItem("token", action.token);
      localStorage.setItem("student", action.student);
      console.log(action,"ACTIONS LISTS");
      localStorage.setItem(
        "instDetail",
        JSON.stringify({ userName: action.user, password: action.password })
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        _id: action._id,
        email: action.email,
        user: action.user,
        adminName: action.adminName,
        userType: action.userType,
        UserTypecode: action.UserTypecode,
        userTypeId: action.userTypeId,
        userTypes: action.userTypes,
        institutionName: action.institutionName,
        student: action.student,
        standard: action.standard,
        section: action.section,
        refInstID: action.refInstID,
        variableID: action.variableID,
        loginUserType: action.loginUserType,
        url: action.url,
        isThirdParty: action?.isThirdParty
      };
    case "LOGIN":
      console.log("hihihihiii", action);
      console.log("hihihihiii", state);
      localStorage.setItem("token", action.token);
      localStorage.setItem("student", action.student);
      localStorage.setItem(
        "instDetail",
        JSON.stringify({ userName: action.user, password: action.password })
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        _id: action._id,
        email: action.email,
        user: action.user,
        adminName: action.adminName,
        userType: action.userType,
        UserTypecode: action.UserTypecode,
        institutionName: action.institutionName,
        userTypeId: action.userTypeId,
        userTypes: action.userTypes,
        student: action.student,
        standard: action.standard,
        section: action.section,
        refInstID: action.refInstID,
        variableID: action.variableID,
        loginUserType: action.loginUserType,
        isThirdParty: action?.isThirdParty
      };
    case "KBC_ROUTES":
      // console.log("hihihihiii", action.route);
      return {
        ...state,
        route: action.route,
        subRoute: action.subRoute,
      };
    case "BOOK_CREDS":
      // console.log("hihihihiii", action.bookCreds);
      return {
        ...state,
        bookCreds: action.bookCreds,
      };

    case "KATBOOKINITIATION":
      // console.log("hihihihiii", action.katbookInitiation);
      localStorage.setItem("katbookInitiation", action.katbookInitiation);
      return {
        ...state,
        katbookInitiation: action.katbookInitiation,
      };

    case "CREDS":
      return { ...state, creds: action.creds };
    case "BM":
      return { ...state, BookMarkList: action.BookMarkList };
    case "NOTES":
      return { ...state, Notes: action.Notes };
    case "ClarifyID":
      return { ...state, ...action };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        katbookInitiation: "",
        userType: null,
        user: null,
        email: "",
        _id: "",
        token: null,
        UserTypecode: "",
        route: "",
        subRoute: "",
        institutionName: "",
        section: "",
        userTypeId: "",
        userTypes: "",
        standard: "",
        student: false,
        refInstID: '',
        variableID: '',
        loginUserType: "",
      };
    case "UserLogger":
      return {
        ...state,
        userLogger: !state.userLogger,
      };
    case "LOG":
      // console.log(action);
      return {
        ...state,
        log: action.log,
      };
    case "ScrollFrom":
      // console.log(action);
      return {
        ...state,
        ...action,
      };
    case "CLASS_SELECT":
      return {
        ...state,
        ...action,
      };
    case "STAFF_TYPE":
      return {
        ...state,
        ...action,
      };
    case "Left_fold":
      return {
        ...state,
        ...action,
      };
    case "state_color":
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

const App = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // const [token, setToken] = useState(state.token);
  const [isloading, setIsloading] = useState(false);

  // state = {
  //   language: 'en'
  // }

  // const languageHandler = (e) => {
  //   e.preventDefault();
  //   let lang = e.target.value;
  //   this.setState(prevState => ({
  //     language: lang
  //   }))
  // }

  useEffect(() => {
    const getProfile = async (token, value, secondData) => {
      // alert('getProfile')
      console.log(value, "VA");
      console.log(secondData, "VA");
      if (!token && !value) {
        dispatch({ type: "LOGOUT" });
      } else if (token) {
        let response;
        try {
          response = await getUserProfile(token);
          if (response.success) {
            console.log(response, "res");
            // console.log(response.success, "test");
            getAllLogFuc();
            dispatch({
              type: "Profile",
              token: response.token,
              _id: response.user._id,
              email: response.user.email,
              user: response.user.userName,
              userType: response.user.userTypeId.userType,
              userTypeId: response.user.userTypeId._id,
              UserTypecode: response.user.userTypeId.code,
              userTypes: response.userTypes,
              student: false,
              isThirdParty: response?.user?.isThirdParty
            });
          } else if (!response.success) {
            toast.error(<Danger body={"Session was time out , Please login again!."} />);
            dispatch({ type: "LOGOUT" });
            //window.location = "/";
          }
        } catch (e) {
          toast.warning(<Warning body={"Something went worng please login!."} />);
          dispatch({ type: "LOGOUT" });
          // window.location = "/";
        }
      } else if (value || secondData.success) {
        var values = JSON.parse(value);
        // console.log(values, "Values");
        let res;
        try {
          res = await KampusLogin({
            userLoginID: values.userName || secondData.user.username,
            password: values.password || secondData.user.code,
          });
          console.log(res,"KAMPUS LOGIN SUCCESS");
          if (res.status === "Login Success") {
            dispatch({
              type: "Profile",
              user: res.value.userName,
              userType: res.value.userType,
              adminName: res.value.adminName,
              value: res.value,
              token: "",
              password: res.value.password,
              institutionName: res.value.institutionName,
              section: res.value.section,
              standard: res.value.standard,
              refInstID: res.value.refInstID,
              variableID: res.value.variableID,
              loginUserType: res.value.userType,
              student: res.value.userType === "Teaching" ? false : true,
              url: values.url || "",
            });
            let address = JSON.parse(res.value.kampusAddress);
            if (address.State === 'Tamil Nadu') {
              dispatch({ type: "state_color", stateColor: "#294d4a" })
            }
            if (address.State === 'Telangana') {
              dispatch({ type: "state_color", stateColor: "#058ED9" })
            }
            dispatch({ type: "CREDS", creds: res.value });
            localStorage.setItem("userCreds", JSON.stringify(res.value));
            deleteKampusStudentLogin();
          } else if (res.status === "failure") {
            toast.error(<Danger body={"Session was time out , Please login again!."} />);
            dispatch({ type: "LOGOUT" });
          }
        } catch (e) {
          // console.log(e);
          toast.warning(<Warning body={"Something went worng please login!."} />);
          dispatch({ type: "LOGOUT" });
          //window.location = "/";
        }
      }
      setIsloading(false);
    };

    const getFuc = async () => {
      if (window.location.href.split("/").indexOf("TeacherView") > 0) {
        // alert("The URL of this page is: " + window.location.href + "1213412");
        const urlArr = window.location.href.split("/");
        // console.log(urlArr, "URLARR");
        // const creds = JSON.parse(urlArr[urlArr.length - 1]);
        // alert(urlArr[urlArr.length - 1]);

        let decoded = atob(urlArr[urlArr.length - 1]);
        let creds = JSON.parse(decoded);
        // console.log(creds, "DECODED");
        let data = JSON.stringify({
          userName: creds.logname,
          password: creds.logpassword,
          url: urlArr[urlArr.length - 1],
        });
        // console.log(data, "DATA@!##%$@^");
        setIsloading(true)
        return getProfile("", data, "");
      }
      if (window.location.href.split("/").indexOf("student") > 0) {
        // alert("The URL of this page is: " + window.location.href + "1213412");
        const urlArr = window.location.href.split("/");
        // console.log(urlArr, "URLARR");
        // const creds = JSON.parse(urlArr[urlArr.length - 1]);
        // alert(urlArr[urlArr.length - 1]);

        let decoded = atob(urlArr[urlArr.length - 1]);
        let creds = JSON.parse(decoded);
        // console.log(creds, "DECODED");
        let data = JSON.stringify({
          userName: creds.logname,
          password: creds.logpassword,
          url: urlArr[urlArr.length - 1],
        });
        // console.log(data, "DATA@!##%$@^");
        setIsloading(true)

        return getProfile("", data, "");
      }
      var addres = address.ip();
      // console.log(addres, "ipconfigd");
      var token = await localStorage.getItem("token");
      var value = await localStorage.getItem("instDetail");
      var secondData;
      if (!token) {
        try {
          secondData = await getKampusStudentLogin();
          if (secondData.success) {
            localStorage.setItem("instDetail", JSON.stringify(secondData.user));
            value = await localStorage.getItem("instDetail");
          }

        } catch (error) {
          toast.error(<Danger body={error} />);
        }
      }
      setIsloading(true);
      var datas = String(window.location).replaceAll('%22', '').replaceAll('%20', '').replaceAll("%EF%BB%BF", "");
      if (datas && !value && !secondData.success) {
        let slenght = datas.search("{");
        let elenght = datas.search("}/");
        if ((elenght !== 0 && slenght !== 0) && (slenght < elenght)) {
          datas = datas.replaceAll('%22', '').replaceAll('%20', '').replaceAll("%EF%BB%BF", "")
          var n = datas.search("username");
          var j = datas.search(",code");
          var k = datas.search("}}");
          let user = datas.substr(n, j - n);
          user = user.substr(user.search(":") + 1, user.length);
          let code = datas.substr(j + 1, k - j - 1);
          code = code.substr(code.search(":") + 1, code.length);
          //console.log(JSON.parse(datas), "project");
          if (user && code) {
            value = JSON.stringify({ "userName": user, "password": code });
          }
        }
      }
      // console.log(value, "project");
      getProfile(token, value, secondData);
    };
    // setInterval(() => {
    getFuc();
    // }, 2000);
  }, []);

  const getAllLogFuc = async () => {
    let result;
    try {
      result = await getAllLog();
      if (result.success) {
        // console.log(result, "result");
        dispatch({ type: "LOG", log: result.logs });
      }
    } catch (e) {
      toast.error(<Danger body={e} />);
    }
  };

  // const KampusSignIn = (creds) =>{

  //   console.log(creds, "CREDENTIALS");
  //   try {
  //     let res = KampusLogin(creds);
  //     if (res.status === 'success') {
  //       dispatch({
  //         type: "Profile",
  //         user: res.value.userName,
  //         userType: res.value.userType,
  //         adminName: res.value.adminName,
  //         value: res.value,
  //         token: '',
  //         password: res.value.password,
  //         institutionName: res.value.institutionName,
  //         section: res.value.section,
  //         standard: res.value.standard,
  //         refInstID: res.value.refInstID,
  //         student: true,
  //       });

  //       dispatch({ type: "CREDS", creds: res.value });

  //    return   localStorage.setItem('userCreds', JSON.stringify(res.value));
  //     }
  //   } catch (e) {

  //   }
  // }

  return (
    <Authcontext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <GlobalContext>
        <EmployeeContext>
          <HashRouter>
            <React.Suspense fallback={loading}>
              {!isloading ? (
                <Switch>
                  {(!state.isAuthenticated) ? (
                    <>
                      <Route
                        exact
                        path="/"
                        name="Login Page"
                        render={(props) => <Login {...props} />}
                      />
                      <Route
                        exact
                        path="/register"
                        name="Register Page"
                        render={(props) => <Register {...props} />}
                      />
                      <Route
                        exact
                        path="/404"
                        name="Page 404"
                        render={(props) => <Page404 {...props} />}
                      />
                      <Route
                        exact
                        path="/500"
                        name="Page 500"
                        render={(props) => <Page500 {...props} />}
                      />
                    </>
                  ) : (
                    state.isAuthenticated && (
                      <Route
                        path="/"
                        name="Dashboard"
                        render={(props) => {
                          //  console.log(props.location.pathname.split('/'),"PROP");

                          if (
                            props.location.pathname
                              .split("/")
                              .includes("BookView") ||  props.location.pathname
                              .split("/")
                              .includes("LinkView")
                          ) {
                            //  console.log(props,"PROP");

                            return <TheContent {...props} style />;
                          }
                          if (
                            props.location.pathname
                              .split("/")
                              .includes("bookList")
                          ) {
                            //  console.log(props,"PROP");

                            return <TheContent {...props} style />;
                          }

                          else {
                            return <TheLayout {...props} />;
                          }
                        }}
                      />
                    )
                  )}
                </Switch>
              ) : (
                <CRow
                  className={"progressBody"}
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <CCol
                    lg="12"
                    style={{ textAlign: "center", marginTop: "15%" }}
                  >

                    <img src={LogoKBIcon} alt="img" /><br /><br />
                    {/* <CSpinner size="lg" variant="grow" /> */}
                    <center><Progress /></center>
                  </CCol>
                </CRow>
              )}
            </React.Suspense>
          </HashRouter>
        </EmployeeContext>
      </GlobalContext>
      <ToastContainer autoClose={3000} />
      <UserLogModel />
    </Authcontext.Provider>
  );
};

export default App;
